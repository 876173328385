import React from 'react';
import {Avatar, Button, Container, TextField, Typography} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";


const SettingsPage = (props) => {
    const [tempSocketServerUrl, setTempSocketServerUrl] = React.useState(props.socketServerUrl);

    React.useEffect(() => {
        setTempSocketServerUrl(props.socketServerUrl);
    }, [props.socketServerUrl]);

    const handleSocketServerChange = React.useCallback((event) => {
        setTempSocketServerUrl(event.target.value);
    }, []);

    const handleSocketServerClick = React.useCallback(() => {
        props.setSocketServerUrl(tempSocketServerUrl);
    }, [tempSocketServerUrl]);

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <SettingsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Settings
            </Typography>

            <TextField
                margin="normal"
                required
                fullWidth
                id="socket-server"
                label="Socket server URL"
                name="socketServer"
                type="text"
                autoComplete="off"
                value={tempSocketServerUrl}
                onChange={handleSocketServerChange}
            />

            <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={handleSocketServerClick}
                disabled={tempSocketServerUrl === props.socketServerUrl}
            >
                Change socket server
            </Button>
        </Container>
    );
}

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
}

export default SettingsPage;
