export const predefinedSafePrimes = [
  "161668339617636067490461367238867828811517913143351189371929621740949344548019272186554382117865098320004427653172503085408031040193857884072382309824922785206910965786898334574234963783562486082767289707306240683287327962979326332113153970652487631780704607598737455429926056243307770615631172042317881954903",
  "154102987734101720642010220388518028605347633241160630050833821556067270467037345597275253141700036038331505391811658334889327906894627240814247834439051314798989024602026403527777126961856855841042592651464072383585554196928445398439557082243290898573259746392464822649244601746605887051402022944576723248843",
  "165671620060650277056521496435752800622672008747940447796721840508167111454325937045108330604169749127700930348516060259187209589621003707520385009277960790439341383404838727354805022495685407501915298128059256588272668744089865469798833803022794615857311338553009126413023413810148690627043199033006717493559",
  "157266303737073625995751070103162424213429579323674391104717152082391775591731239418214676345131519121429992386021702773773581745005952076927024210855819585400356757997418985345405476017099539246165650956551666053057761025628117922035844400451157790689121486076687041340262183842034105763835673778879742790859",
  "159270145435505007853639972596549286535222719305402358712190825854608158124651696259183503983257812706619775093181580535374737143212347654912420125952773229474754109133195931576179044771880839119684936243414701873947841681910017714262887149076579012228454856506143196335835356889577006396732460897705768962343",
  "161107245259702180107123117853096092583441479273237705920529436901996413695015235878447090346484214184251889021110180939884370273390984383893789229385514002046287801364213046058176322413427224292049541547821655749036524238611941129691710598307354403401815643021122865266618527763421383418393516149233260252723",
  "139115534070726009524787564418549253810320444652159266580564163488657087716881916411151244606213718489441132108335313962622970174898921481823643533458081864996080556428974716714445305216120774727389301664870850058893423211659892765175432039879854932892420560447072919992713826596122035500162971401290861650867",
  "143628090309487232569355274920576231617418176347921066229740640297543053149383005768255146696892362708523330276959181027294911383683271180921649595424638288703498060602055372733320223355221254594321734274614626035894076820635619828359234533688222854557732058219062021896852367280779919330035024438275388489947",
  "141860922928688340536574898654427109383652133548669404270454739060268488026940208631829243150057531538185938156757771794670772354554953607944098834363104810568568109249750246084775126063098732102290362172549466939381138806765755322106776639997400718249869684389800333468586830138886855444240776305193801471383",
  "175537798769805546298965662470302066221567741134745145860001067060620950125684683964023695016513350420200663854104914363160104185089777867543826041794791024762533597188408531205755537763873359319690977396112144085789586824037875667802299323360425413068885216888967901311914895239971783818537362910539218873783",
  "178283023016934070479213585413909245735587673833110304265354463241278443805723996989306216221594015260533221234322473244251087353903388714853076642773628382772822784327948314103739546168179203274850002007103000164104628612125066910229021117242087843780016215030671949808306202233309184868021854234824174640987",
  "138954202464206305251411399990424828359941956653673920982110419510620987218270275505988367600255383645629246277252355510479044844841958313574832786415359642085431893499555459703696780842300109630596484237126358142313939478557846809142764142694777168947367344507173222512682373487232642955150916641818284509379",
  "141933307012787235036570214869020895260111795074125910501406793902407188325447801075000314587324366776472212271580418320900775947787123796758878339911748449069682549333021646887694600354623640341495880185456972160584281373106508496668232365491403190609426648478105525370508925761974458144047796466640945048467",
  "156981577815929420043788589990599884458560186283612671171892493740625177475669020934553149353251351794775900094766623539194007247213515914761373069581120964768323763412637304436468059660061153105819454014217335007640989248083527661546811031921848203336936623921483952939352560710414461901579050199655033220527",
  "144198344441101388628349904757720856894026680127284677084902096232737205410792892158053343248711772193405925663173479311813374001704421900620132482631439271452392895275550263127203612658996359592820142449159510899865300858958879675421339072703380959763425300764301182754102915670311528426586091795292107045923",
  "163236597118507950748718976675200571268130044583519820870788681500809713425082907792920641979109959813601444668699867903989444950504238581601159913987809374897743505359391037698394007896201214852691093542138782690624763030707586515806459496826552744214535478672965728100312748218926727221386609198334598155407",
  "137119170545604405030209744338542028895236842875559126152438542519268807650199726075835635987764670640374352088365463730717386542134001169254365857862340436220184813501243572896006009279389718246999873257951400189980662926423650311942648713138686601632318219335334737447353517436732876519806550149139737546399",
  "175352678615515531462389799951482619451034046935906343326427376639545005086564713194581254201415732449212879888256520325516440402080265285206299072301200512533055260928083222984633598696543886422619412241874728165818099789779502946371768853324705319547079099725645764860055046273900787457232769529917559136919",
  "169827555910663739997147583300929171780486909354102143986909269895211298349534458438107510444387590289598743015640753448433252177454881127884287454823778373468343426196443311772364777548113016667863686253235267177935444802564270220466557858831980600823769019101840386466524941054437627101259330676365374131859",
  "154396374567086154150426140967701628482957593667678017558581525535516315469522353770411294753960938970290320215150976028924204247253147957373497980574974760572790622093428232807339671875323961195656815892251483914540149726748420692719698845456678049174425294340487572186610878674807392714634918250652686664799",
  "171006166961839733496021521531748228108697734416426659560466895609813093134944186193952853095304574041181630531328306292736066403259315821101122820411189818847746403115528019529935332338474381517098735202506469147731318599006168395744017614431319168176435776413193912765529934757926418869484911051452554828307",
  "137556429892463668338512861455491837212429745469193890166066748019540869574096862639413479393675749298427276563955822753860833837368555354331109012307036185914497679451596587574065005324709717987620264655708405401373438073428119156719360834439962599009773846913948509767177633924456477125038255880216920126647",
];
