import React from "react";
import {io} from "socket.io-client";
import JSONBig from "json-bigint";
import {participantStrWithoutBigIntStr} from "../utils";
import * as tss from "@xdefi-tech/crypto_tss";
import * as wasmInit from "@xdefi-tech/crypto_tss/dist/wasm_exec";
import modes from "../constants/modes";
console.log({ tss, wasmInit });

const getModeParticipant = (mode, participant) => {
    switch (mode) {
        case modes.ethereum:
            const gg20ParticipantStr = JSONBig.stringify(participant.gg20.participant);
            const fixedParticipantStr = participantStrWithoutBigIntStr(gg20ParticipantStr);
            return new tss.GG20Participant(fixedParticipantStr);
        case modes.solana:
            const ted25519ParticipantString = JSON.stringify(participant.ted25519.participant);
            return new tss.Ted25519Participant(participant.ted25519.config, ted25519ParticipantString);
        default:
            throw new Error(`Unsupported mode: ${mode}`);
    }
}

const getParticipantId = (participant, mode) => {
    switch (mode) {
        case modes.ethereum:
            return parseInt(participant.gg20.participant.Id);
        case modes.solana:
            return parseInt(participant.ted25519.participant.identifier);
        default:
            throw new Error(`Unsupported mode: ${mode}`);
    }
}

const useSocket = (serverUrl, mode) => {
    const ioClient = React.useRef(null);
    const [currentRoom, setCurrentRoomId] = React.useState(null);
    const [roomParticipants, setRoomParticipants] = React.useState([]);
    const [signature, setSignature] = React.useState(null);
    const [participant, setParticipant] = React.useState(null);

    React.useEffect(() => {
        const url = new URL(serverUrl)
        const client = io(url.origin, {
            transports: ['websocket'],
            path: `${url.pathname}${url.pathname.endsWith('/') ? '' : '/'}socket.io`,
        });
        ioClient.current = client;
        return () => {
            client.disconnect();
        }
    }, [serverUrl, mode]);

    React.useEffect(() => {
        if (!ioClient.current) {
            return
        }

        const signRequestHandler = ({ digest, from, mode: requestMode }) => {
            const signRoomId = `${currentRoom}_${digest}`;
            const res = window.confirm(`Sign request from ${from}, digest: ${digest}?`);

            if (!res) {
                return;
            }

            // ggparticipant
            const signingRoom = {
                sendMsg: (msg, to) => {
                    console.debug("init sendMsg", msg, to);
                    if (to === undefined) {
                        console.log("broadcast round");
                        ioClient.current.emit(signRoomId, "broadcast round", msg);
                    } else if (to === 0) {
                        console.log("signature", msg);
                        ioClient.current.emit(signRoomId, "signature", msg);
                    } else {
                        console.log("p2p round");
                        ioClient.current.emit(signRoomId, "p2p round", msg, to);
                    }
                },
                onmessage: undefined, // will be override when .sign() is called
            };

            // confirm
            ioClient.current.emit("sign:response", {
                roomId: currentRoom,
                participantId: parseInt(participant.gg20.participant.Id)
            });
            ioClient.current.on(signRoomId, (command, data) => {
                console.debug("io::round", command, data);
                if (command === "sign") {
                    console.log('participant', typeof participant, participant);

                    const p = getModeParticipant(requestMode, participant);

                    console.log({
                        msg: "sign response",
                        digest,
                        cosigners: roomParticipants,
                        roomId: currentRoom,
                        signingRoom,
                        signRoomId
                    });

                    p.sign(
                        digest,
                        roomParticipants,
                        signingRoom,
                        mode === modes.ethereum ? window.gg20 : window.ted25519
                    );
                }
                if (command === "round") signingRoom.onmessage(data);
                if (command === "signature") {
                    setSignature(data);
                    clear();
                }
            });
        };

        ioClient.current.on("sign:request", signRequestHandler);

        return () => {
            if (!ioClient.current) {
                return
            }
            ioClient.current.off("sign:request", signRequestHandler);
        }
    }, [roomParticipants]);

    const init = React.useCallback((roomId, participant) => {
            console.log('init', roomId);
        if (!roomId) {
            return;
        }
        const client = ioClient.current;
        const id = getParticipantId(participant, mode);
        if (currentRoom) {
            client.emit("room:leave");
            client.removeAllListeners();
        }
        client.on(roomId, (participants) => {
            console.log('Update room participants', participants);
            setRoomParticipants(participants);
        })
        client.emit("room:join", {
            roomId,
            idx: id
        });
        setCurrentRoomId(roomId);
        setParticipant(participant);
    },
        [currentRoom, mode]
    );

    const sign = React.useCallback((digest) => {
        const signRoomId = `${currentRoom}_${digest}`;
        const signingRoom = {
            sendMsg: (msg, to) => {
                console.debug("sign sendMsg", msg, to);
                if (to === undefined) {
                    console.log("broadcast round");
                    ioClient.current.emit(signRoomId, "broadcast round", msg);
                } else if (to === 0) {
                    console.log("signature", msg);
                    ioClient.current.emit(signRoomId, "signature", msg);
                } else {
                    console.log("p2p round");
                    ioClient.current.emit(signRoomId, "p2p round", msg, to);
                }
            },
            onmessage: undefined, // will be override when .sign() is called
        };

        const participantId = getParticipantId(participant, mode);

            console.log('"sign:request"', participantId)
        ioClient.current.emit("sign:request", {
            roomId: currentRoom,
            digest,
            mode,
            from: participantId
        });
        let signing = false;
        ioClient.current.on(signRoomId, (command, data) => {
            console.debug("io::round", command, data);
            if (command === "participants" && data.length >= 2) {
                if (signing) {
                    return;
                }
                signing = true;
                ioClient.current.emit(signRoomId, 'sign', {
                    digest,
                    cosigners: data
                });
                const p = getModeParticipant(mode, participant);
                console.log({
                    msg: "sign request",
                    digest,
                    cosigners: data,
                    roomId: currentRoom,
                    signingRoom,
                    signRoomId
                });

                p.sign(
                    digest,
                    data,
                    signingRoom,
                    mode === modes.ethereum ? window.gg20 : window.ted25519
                );
            }
            if (command === "round") signingRoom.onmessage(data);
            if (command === "signature") {
                setSignature(data);
                clear();
            }
        });
    },
        [currentRoom, participant, roomParticipants, mode]
    );

    const clear = React.useCallback(() => {
        ioClient.current.emit("room:leave");
        ioClient.current.removeAllListeners();
        setCurrentRoomId(null);
        setParticipant(false);
        setRoomParticipants([]);
    },
        []
    );

    return {
        signature,
        roomParticipants,
        init,
        clear,
        sign
    }
}

export default useSocket;
