import {ethersProvider, solanaProvider} from "../common/api/provider";
import * as web3 from "@solana/web3.js";

export const broadcastEthTransaction = async (signedTx) => {
    return ethersProvider.sendTransaction(signedTx);
}

export const broadcastSolTransaction = async (signedTx) => {
    const buffer = Buffer.from(signedTx, 'hex');
    return web3.sendAndConfirmRawTransaction(solanaProvider, buffer);
}

export default {
    broadcastEthTransaction,
    broadcastSolTransaction
}