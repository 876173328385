import {ethers} from "ethers";
import {ROPSTEN_API_KEY} from "../../constants/api";
import * as web3 from '@solana/web3.js';

const network = ethers.providers.getNetwork("ropsten", ROPSTEN_API_KEY);
export const ethersProvider = new ethers.providers.EtherscanProvider(network);
export const solanaProvider = new web3.Connection(web3.clusterApiUrl('devnet'), 'confirmed');

export default {
    ethersProvider,
    solanaProvider
};
