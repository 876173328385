"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThresholdSigning = void 0;
const { encoded } = require("./encoded_wasm");
function asciiToBinary(str) {
    if (typeof atob === "function") {
        // this works in the browser
        return atob(str);
    }
    else {
        // this works in node
        return new Buffer(str, "base64").toString("binary");
    }
}
function decode(encoded) {
    var binaryString = asciiToBinary(encoded);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
var ThresholdSigning;
(function (ThresholdSigning) {
    async function Init() {
        const go = new Go();
        // return WebAssembly.instantiateStreaming(
        return WebAssembly.instantiate(decode(encoded), 
        // await fetch(__dirname + "./threshold_signing.wasm"),
        go.importObject)
            .then((result) => {
            go.run(result.instance);
            return {
                gg20: !!gg20,
                ted25519: !!ted25519,
            };
        })
            .catch((err) => {
            console.error(err);
            return err;
        });
    }
    ThresholdSigning.Init = Init;
})(ThresholdSigning = exports.ThresholdSigning || (exports.ThresholdSigning = {}));
