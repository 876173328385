import { Point } from "@noble/secp256k1";
import JSONBig from "json-bigint";
import { Address } from "ethereumjs-util";
import * as web3 from "@solana/web3.js";

export function gg20OutputToPoint(str) {
  let obj = str;
  if (typeof str !== "object")
    obj = JSONBig({ storeAsString: false }).parse(str);
  // debugger
  console.debug('gg20OutputToPoint', obj);
  const x = typeof obj.X === "string" ? obj.X : obj.X.toFixed();
  const y = typeof obj.Y === "string" ? obj.Y : obj.Y.toFixed();
  return new Point(BigInt(x), BigInt(y));
}

export function gg20ToEthAddress(str) {
  const point = gg20OutputToPoint(str);
  return Address.fromPublicKey(
    Buffer.from(point.toRawBytes(false).slice(1))
  ).toString();
}

export function participantStrToParticipant(str) {
  const regex = /:([\d]+)/gm;
  const subst = `:"$1"`;
  const pStrFixed = str.replace(regex, subst);
  return JSONBig.parse(pStrFixed);
}

export function participantStrWithoutBigIntStr(str) {
  const regex = /:"([\d]+)"/gm;
  const subst = `:$1`;
  return str.replace(regex, subst);
}

export function ed25519ToSolanaPublicKey(ed255199publicKey) {
  // Solution for browser
  // const buffer = new Uint8Array(ed255199publicKey.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16)));
  const buffer = Buffer.from(ed255199publicKey, 'hex');

  return new web3.PublicKey(buffer);
}
