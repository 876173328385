import {
    gg20ToEthAddress,
    participantStrToParticipant
} from "../utils";
import JSONBig from "json-bigint";

export const parseParticipant = (participantString) => {
    const p = JSONBig.parse(participantString);

    return {
        gg20: parseGG20Participant(p.gg20),
        ted25519: {
            participant: JSON.parse(p.ted25519.ed25519Participant),
            address: p.ted25519.ed25519PublicKey,
            config: p.ted25519.config
        }
    };
}

export const parseGG20Participant = (participantString) => {
    const participantObject = participantStrToParticipant(participantString);
    const participantAddress = gg20ToEthAddress(participantObject.EcdsaPublicKey);

    return {
        participant: participantObject,
        address: participantAddress
    };
};

