import React from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    TextField,
    Typography
} from "@mui/material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import JSONBig from "json-bigint";
import ImportParticipantComponent from "../../components/participant/import-participant.component";
import {ed25519ToSolanaPublicKey} from "../../utils";


const ParticipantPage = (props) => {
    const [showImport, setShowImport] = React.useState(false);
    const [participantString, setParticipantString] = React.useState(JSONBig.stringify(props.currentParticipant));

    const showUpdateParticipantButton = React.useMemo(() => {
        return participantString !== JSONBig.stringify(props.currentParticipant)
    }, [participantString]);

    React.useEffect(() => {
        setParticipantString(JSONBig.stringify(props.currentParticipant));
        setShowImport(false);
    }, [props.currentParticipant]);

    const handleChangeParticipant = React.useCallback((event) => {
        setParticipantString(event.target.value);
    }, [props.currentParticipant]);

    const handleUpdateParticipant = React.useCallback(() => {
        try {
            props.setCurrentParticipant(participantString);
        } catch (err) {
            setParticipantString(JSONBig.stringify(props.currentParticipant));
            console.error(err);
        }
    }, [participantString]);

    const handleImportParticipant = React.useCallback((participant) => {
        props.setCurrentParticipant(participant);
    }, []);

    const handleShowImport = React.useCallback(() => {
        setShowImport(true);
    }, []);

    const ethAddress = React.useMemo(() =>  props.currentParticipant && props.currentParticipant.gg20.address,
        [props.currentParticipant]
    );
    const solAddress = React.useMemo(() => props.currentParticipant && ed25519ToSolanaPublicKey(props.currentParticipant.ted25519.address).toString(),
        [props.currentParticipant]
    );

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <AccessibilityIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Current Participant
            </Typography>

            {(!props.currentParticipant) &&
                <Typography component="h2" variant="h6">
                    Set participant first
                </Typography>
            }

            {props.currentParticipant &&
                <>
                    <Box sx={styles.textField}>
                        <TextField
                            margin="normal"
                            id="participant"
                            label="Participant string"
                            name="participant"
                            type="text"
                            value={participantString}
                            onChange={handleChangeParticipant}
                            fullWidth
                        />

                        {showUpdateParticipantButton &&
                        <Button
                            sx={styles.button}
                            variant="contained"
                            fullWidth
                            onClick={handleUpdateParticipant}
                        >
                            Update
                        </Button>
                        }
                    </Box>

                    {ethAddress &&
                        <Typography>
                            ETH address: {ethAddress}
                        </Typography>
                    }

                    {solAddress &&
                        <Typography>
                            SOL address: {solAddress}
                        </Typography>
                    }
                </>
            }

            {
                showImport ?
                    <ImportParticipantComponent
                        socketServerUrl={props.socketServerUrl}
                        onImport={handleImportParticipant}
                    /> :
                    <Button
                        sx={styles.button}
                        variant="contained"
                        fullWidth
                        onClick={handleShowImport}
                    >
                        Import
                    </Button>
            }

        </Container>
    );
}

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    button: {
        mt: 2
    },
    textField: {
        width: '100%'
    }
};

export default ParticipantPage;
