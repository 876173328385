import React from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    TextField,
    Typography
} from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import {generateRawEd25519Transaction, generateRawGG20Transaction} from "../../functions/generate-raw-transaction";
import mods from "../../constants/modes";


const RawTransactionPage = (props) => {
    const [tx, setTx] = React.useState(null);
    const [digest, setDigest] = React.useState(null);

    const handleGenerateTransactionSubmit = React.useCallback(async (event) => {
        try {
            /* Form data */
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            console.debug({ data });
            const parsedData = {
                recipient: data.get("recipient"),
                txData: data.get("txData"),
                txValue: data.get("txValue")
            };
            console.debug({ parsedData });

            /* Generating transaction*/
            let tx, digest;

            if (props.mode === mods.ethereum) {
                const fromAddress = props.participant.gg20.address;
                const txData = await generateRawGG20Transaction(fromAddress, parsedData);
                tx = txData.tx;
                digest = txData.digest;
            } else if (props.mode === mods.solana) {
                const fromAddress = props.participant.ted25519.address;
                const txData = await generateRawEd25519Transaction(fromAddress, parsedData);
                tx = txData.tx;
                digest = txData.digest;

                props.setTransaction(txData.transaction, txData.payer)
            }

            setTx(tx);
            setDigest(digest);
        } catch (err) {
            console.error(err);
        }
    },[props.mode, props.participant]);

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <PendingIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Generate unassigned raw transaction
            </Typography>
            <Box
                component="form"
                onSubmit={handleGenerateTransactionSubmit}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="recipient"
                    label="Recipient address"
                    name="recipient"
                    type="text"
                    autoComplete="off"
                    defaultValue=""
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="txData"
                    label="Transaction data (hex)"
                    name="txData"
                    type="text"
                    autoComplete="off"
                    defaultValue=""
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="txValue"
                    label="Transaction value"
                    name="txValue"
                    type="number"
                    autoComplete="off"
                    defaultValue="1"
                    inputProps={{
                        min: 1
                    }}
                />

                {tx && digest &&
                    <Box>
                        <Typography>
                            Unassigned transaction hash:
                        </Typography>

                        <Typography sx={styles.text}>
                            {tx}
                        </Typography>

                        <Typography>
                            Digest to sign:
                        </Typography>

                        <Typography sx={styles.text}>
                            {digest}
                        </Typography>
                    </Box>
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                >
                    Generate
                </Button>
            </Box>
        </Container>
    );
};

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    button: {
        mt: 3,
        mb: 2
    },
    text: {
        wordBreak: "break-all"
    }
};

export default RawTransactionPage;
