import React from "react";
import {
    Avatar,
    CircularProgress,
    Box,
    Typography,
    Container,
    Button
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import JSONBig from "json-bigint";
import {ed25519ToSolanaPublicKey, participantStrToParticipant, gg20ToEthAddress} from "../../utils";
import INIT_DEALER from "../../constants/initiatedDealer";
import generateDealer from "../../functions/generate-dealer";
import GenerateDealerForm from "./generate-dealer.form";
import ShowDealerComponent from "./show-dealer.component";
import ExportParticipantComponent from "../../components/participant/export-participant.component";


const DealerPage = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [participants, setParticipants] = React.useState(null);
    const [participantToExport, setParticipantToExport] = React.useState(null);

    React.useEffect(() => {
        if (!props.dealer) {
            setParticipants(null);
            return;
        }

        // Sort and merge participants
        const gg20participants = props.dealer.ecdsaParticipants.sort((a, b) => {
            const pA = participantStrToParticipant(a);
            const pB = participantStrToParticipant(b);

            return Number(pA.Id) - Number(pB.Id);
        });
        const ted25519Participants = props.dealer.ed25519Participants.sort((a, b) => {
            const pA = JSON.parse(a);
            const pB = JSON.parse(b);

            return Number(pA.identifier) - Number(pB.identifier);
        });
        const participants = gg20participants.map((p, idx) => ({
            gg20: p,
            ted25519: {
                ed25519Participant: ted25519Participants[idx],
                ed25519PublicKey: props.dealer.ed25519PublicKey,
                config: {
                    type: "ted25519",
                    pubkey: props.dealer.ed25519PublicKey,
                    ...props.dealer.config
                }
            }
        }));

        console.log('props.dealer.ed25519PublicKey', props.dealer.ed25519PublicKey)

        setParticipants(participants);
        setParticipantToExport(null);
    }, [props.dealer]);

    const handleSetDefaultDealerClick = React.useCallback(() => {
        setLoading(true);
        const dealer = JSONBig.parse(INIT_DEALER);
        props.setDealer(dealer);
        setLoading(false);
    }, []);

    const handleGenerateDealerClick = React.useCallback(async (data) => {
        try {
            setLoading(true);
            const dealer = await generateDealer(data);
            props.setDealer(dealer);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleClearDealerClick = React.useCallback(() => {
        props.setDealer(null);
        setParticipantToExport(null);
    }, []);

    const handleSetCurrentParticipant = React.useCallback((participant) => {
        props.setCurrentParticipant(participant);
        setParticipantToExport(null);
    }, []);

    const handleExport = React.useCallback((participant) => {
        setParticipantToExport(participant);
    }, []);

    const handleResetExportedParticipant = React.useCallback(() => {
        setParticipantToExport(null);
    }, []);

    const ethAddress = React.useMemo(() => props.dealer && gg20ToEthAddress(props.dealer.ecdsaPublicKey),
        [props.dealer]
    );
    const solAddress = React.useMemo(() => props.dealer && ed25519ToSolanaPublicKey(props.dealer.ed25519PublicKey).toString(),
        [props.dealer]
    );

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                MPC Generation
            </Typography>

            {loading &&
                <Box sx={styles.loader}>
                    <Typography>
                        Loading
                    </Typography>
                    <CircularProgress size={40} />
                </Box>
            }

            {!loading &&
                <>
                    {participants &&
                        <>
                            <ShowDealerComponent
                                participants={participants}
                                onClear={handleClearDealerClick}
                                onSet={handleSetCurrentParticipant}
                                onExport={handleExport}
                            />

                            {ethAddress &&
                                <Box>
                                    ETH address: {ethAddress}
                                </Box>
                            }

                            {solAddress &&
                                <Box>
                                    SOL address: {solAddress}
                                </Box>
                            }
                        </>
                    }

                    {!participants &&
                        <GenerateDealerForm
                            onSubmit={handleGenerateDealerClick}
                        />
                    }

                    {participantToExport &&
                        <ExportParticipantComponent
                            participantStr={participantToExport}
                            socketServerUrl={props.socketServerUrl}
                            onReset={handleResetExportedParticipant}
                        />
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSetDefaultDealerClick}
                        sx={styles.button}
                    >
                        Set default Dealer
                    </Button>
                </>
            }
        </Container>
    );
};

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    loader: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    button: {
        my: 2
    }
};

export default DealerPage;
