import {ethers} from "ethers";
import {ethersProvider, solanaProvider} from "../common/api/provider";
import * as web3 from "@solana/web3.js";

export const checkGG20Balance = async (address) => {
    const balance = await ethersProvider.getBalance(address);

    return ethers.utils.formatEther(balance); // Convert wei to ether
}

export const checkEd25519Balance = async (base58PubKey) => {
    const pubkey = new web3.PublicKey(base58PubKey);
    const lamportsBalance = await solanaProvider.getBalance(pubkey);

    return lamportsBalance / Math.pow(10, 9);
}
