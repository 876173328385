import {ethers} from "ethers";
import {ethersProvider} from "../common/api/provider";

// recoveryId {0,1}
const _signTransaction = (tx, signature, chainId, recoveryId) => {
    const joinedSignature = ethers.utils.joinSignature({
        r: ethers.BigNumber.from(signature.R).toHexString(),
        s: ethers.BigNumber.from(signature.S).toHexString(),
        v: chainId * 2 + 35 + recoveryId // TODO calculate recoveryId
    });

    return ethers.utils.serializeTransaction(tx, joinedSignature);
}

// todo move getting chainID out of this function for testing and multiple signing to avoid multiple requests
export const signTransaction = async (serialisedUnsignedTransaction, signature, from) => {
    const unassignedTransaction = ethers.utils.parseTransaction(serialisedUnsignedTransaction);
    const {r,s,v, ...tx} = unassignedTransaction;
    const chainId = (await ethersProvider.getNetwork()).chainId;
    const lowCaseFrom = from.toLowerCase();

    const firstTry = _signTransaction(tx, signature, chainId, 1);
    const parsedFirstTry = ethers.utils.parseTransaction(firstTry);

    if (parsedFirstTry.from.toLowerCase() === lowCaseFrom) {
        return firstTry;
    }

    const secondTry = _signTransaction(tx, signature, chainId, 0);
    const parsedSecondTry = ethers.utils.parseTransaction(secondTry);

    if (parsedSecondTry.from.toLowerCase() === lowCaseFrom) {
        return secondTry;
    }

    console.warn('Cannot sign transaction with this signature');
    return null;
}