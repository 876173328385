import React from "react";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography
} from "@mui/material";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {checkEd25519Balance, checkGG20Balance} from "../../functions/check-balance";
import mods from "../../constants/modes";


const BalancePage = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [address, setAddress] = React.useState('');
    const [balance, setBalance] = React.useState('');

    const handleAddressChange = React.useCallback((event) => {
        setAddress(event.target.value);
    }, []);

    const handleGetBalanceClick = React.useCallback(async() => {
        try {
            setLoading(true);
            let balance;
            if (props.mode === mods.ethereum) {
                balance = await checkGG20Balance(address);
            } else if (props.mode === mods.solana) {
                balance = await checkEd25519Balance(address);
            }

            setBalance(balance);
        } catch (err) {
            console.error(err);
            setBalance(err.message);
        } finally {
            setLoading(false);
        }
    }, [address, props.mode]);

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <MonetizationOnIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Check balance
            </Typography>

            {loading &&
                <Box sx={styles.loader}>
                    <Typography>
                        Getting balance
                    </Typography>
                    <CircularProgress size={40} />
                </Box>
            }

            <TextField
                margin="normal"
                required
                fullWidth
                id="recipient"
                label="Recipient address"
                name="recipient"
                type="text"
                autoComplete="off"
                value={address}
                onChange={handleAddressChange}
            />

            {balance &&
                <Box>
                    <Typography>
                        Balance:
                    </Typography>
                    <Typography>
                        {balance}
                    </Typography>
                </Box>
            }


            <Button
                type="button"
                fullWidth
                variant="contained"
                sx={styles.button}
                onClick={handleGetBalanceClick}
            >
                Check balance
            </Button>
        </Container>
    )
};

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    button: {
        mt: 3,
        mb: 2
    },
    loader: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
};

export default BalancePage;
