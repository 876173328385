import React from "react";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import {Avatar, Button, Container, TextField, Typography} from "@mui/material";
import {broadcastEthTransaction, broadcastSolTransaction} from "../../functions/broadcast-transaction";
import modes from "../../constants/modes";


const BroadcastPage = (props) => {
    const [tx, setTx] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState(null);

    const handleChangeTransaction = React.useCallback((event) => {
        setTx(event.target.value);
    }, []);

    const handleBroadcastClick = React.useCallback(async () => {
        try {
            setLoading(true);
            let response = null;

            if (props.mode === modes.ethereum) {
                response = await broadcastEthTransaction(tx);
            } else if (props.mode === modes.solana) {
                response = await broadcastSolTransaction(tx);
            }

            setResponse(response);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [tx, props.mode]);

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <PodcastsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Broadcast transaction
            </Typography>

            <TextField
                margin="normal"
                required
                fullWidth
                id="raw-tx"
                label="Signed transaction (hash)"
                name="rawTx"
                type="text"
                autoComplete="off"
                value={tx}
                onChange={handleChangeTransaction}
            />

            {response &&
                <Typography sx={styles.text}>
                    {JSON.stringify(response)}
                </Typography>
            }

            <Button
                type="button"
                fullWidth
                variant="contained"
                sx={styles.button}
                onClick={handleBroadcastClick}
                disabled={loading}
            >
                Broadcast
            </Button>
        </Container>
    );
}

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    button: {
        mt: 3,
        mb: 2
    },
    text: {
        wordBreak: "break-all",
        my: 1
    },
};

export default BroadcastPage;

