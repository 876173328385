import React from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import JSONBig from "json-bigint";


const ShowDealerComponent = (props) => {
    const handleOnSetClick = React.useCallback((participant) => () => {
        props.onSet(participant);
    }, []);

    const handleOnExportClick = React.useCallback((participant) => () => {
        props.onExport(participant);
    }, []);

    return (
        <Box>
            <Typography component="h2" variant="h6">
                Generated participants
            </Typography>
            <Box>
                {props.participants.map((d, idx) => {
                    const participantId = idx + 1;
                    return (
                        <Box sx={styles.item} key={participantId}>
                            <TextField
                                margin="normal"
                                id={`participant-${participantId}`}
                                label={`Participant ${participantId}`}
                                name={`participant-${participantId}`}
                                type="text"
                                defaultValue={JSONBig.stringify(d)}
                                fullWidth
                                InputProps={{
                                    readOnly: true
                                }}
                            />

                            <Button sx={styles.button} onClick={handleOnSetClick(JSONBig.stringify(d))} variant="outlined">
                                Set
                            </Button>

                            <Button
                                sx={styles.button}
                                onClick={handleOnExportClick(JSONBig.stringify(d))}
                                variant="outlined"
                                color="secondary"
                            >
                                Export
                            </Button>
                        </Box>
                    );
                })}
            </Box>

            <Button
                type="submit"
                fullWidth
                onClick={props.onClear}
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
            >
                Generate another
            </Button>
        </Box>
    )
}

const styles = {
    item: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        ml: 2,
        height: '100%'
    }
}

export default ShowDealerComponent;
