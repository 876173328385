"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ted25519Participant = exports.GG20Participant = exports.Ted25519Signer = exports.GG20Signer = void 0;
class GG20Signer {
    constructor(participant, idx, digest, cosigners, sendMsg, gg20Instance) {
        this.round = 0;
        this.signersMessages = [];
        this.signing = false;
        this.idx = idx;
        this.sendMsg = sendMsg;
        this.cosigners = cosigners;
        const s = gg20Instance.NewSigner(participant, cosigners);
        if (s instanceof Error)
            throw s;
        this.signer = s;
        this.digest = new Uint8Array(digest.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16)));
    }
    sign() {
        if (this.signing)
            return Promise.reject(new Error("Signer is already busy"));
        this.signing = true;
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            try {
                this.processRound();
            }
            catch (e) {
                this.reject(e);
            }
        });
    }
    handleMessage(msg) {
        if (msg.round !== this.round) {
            console.warn(`${this.idx}: Got message for round ${msg.round} while in round ${this.round}`, msg);
            return;
        }
        if (this.idx === msg.from) {
            console.warn(`${this.idx}: Got message from ${msg.from} which is herself`);
            return;
        }
        if (!this.cosigners.includes(msg.from)) {
            console.warn(`${this.idx}: Got message from ${msg.from} which is not in the cosigners list`, msg);
            return;
        }
        if (msg.round === this.round) {
            this.signersMessages.push([msg.from, msg.data]);
            console.log(`${this.idx}: Added message from ${msg.from} for round ${msg.round}`);
            if (this.signersMessages.length < this.cosigners.length - 1)
                return;
        }
        try {
            this.processRound();
        }
        catch (e) {
            this.reject(e);
        }
    }
    processRound() {
        console.log(`${this.idx}: Starting round ${this.round + 1}`);
        const broadcast = (data) => {
            this.signersMessages = [];
            if (data instanceof Error)
                throw data;
            this.sendMsg({ round: this.round, from: this.idx, data });
        };
        switch (++this.round) {
            case 1: {
                const bcast = this.signer.SignRound1();
                broadcast(bcast);
                break;
            }
            case 2: {
                const p2p = this.signer.SignRound2(this.signersMessages);
                this.signersMessages = [];
                if (p2p instanceof Error)
                    throw p2p;
                for (let [dst, data] of p2p) {
                    if (dst != this.idx)
                        this.sendMsg({ round: 2, data, from: this.idx }, dst);
                }
                break;
            }
            case 3: {
                const bcast = this.signer.SignRound3(this.signersMessages);
                broadcast(bcast);
                break;
            }
            case 4: {
                const bcast = this.signer.SignRound4(this.signersMessages);
                broadcast(bcast);
                break;
            }
            case 5: {
                const bcast = this.signer.SignRound5(this.signersMessages);
                broadcast(bcast);
                break;
            }
            case 6: {
                const bcast = this.signer.SignRound6(this.signersMessages, this.digest);
                broadcast(bcast);
                break;
            }
            case 7: {
                const signature = this.signer.SignOutput(this.signersMessages);
                this.signersMessages = [];
                if (signature instanceof Error)
                    throw signature;
                this.sendMsg({ round: this.round, from: this.idx, signature }, 0);
                this.resolve(signature);
                this.signer.Release();
            }
        }
    }
}
exports.GG20Signer = GG20Signer;
class Ted25519Signer {
    constructor(config, participant, idx, message, cosigners, sendMsg, ted25519Instance) {
        this.round = 0;
        this.signersMessages = [];
        this.signing = false;
        this.idx = idx;
        this.sendMsg = sendMsg;
        this.cosigners = cosigners;
        const s = ted25519Instance.NewSigner(config, participant, cosigners);
        if (s instanceof Error)
            throw s;
        this.signer = s;
        this.message = new Uint8Array(message.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16)));
    }
    sign() {
        if (this.signing)
            return Promise.reject(new Error("Signer is already busy"));
        this.signing = true;
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            try {
                this.processRound();
            }
            catch (e) {
                this.reject(e);
            }
        });
    }
    handleMessage(msg) {
        if (msg.round !== this.round) {
            console.warn(`${this.idx}: Got message for round ${msg.round} while in round ${this.round}`, msg);
            return;
        }
        if (!this.cosigners.includes(msg.from) || this.idx === msg.from) {
            console.warn(`${this.idx}: Got message from ${msg.from} which is not in the cosigners list`, msg);
            return;
        }
        if (msg.round === this.round) {
            this.signersMessages.push(msg.data);
            console.log(`${this.idx}: Added message from ${msg.from} for round ${msg.round}`);
            if (this.signersMessages.length < this.cosigners.length - 1)
                return;
        }
        try {
            this.processRound();
        }
        catch (e) {
            this.reject(e);
        }
    }
    processRound() {
        console.log(`${this.idx}: Starting round ${this.round + 1}`);
        const broadcast = (data) => {
            this.signersMessages = [];
            if (data instanceof Error)
                throw data;
            this.sendMsg({ round: this.round, from: this.idx, data });
        };
        switch (++this.round) {
            case 1: {
                const p2p = this.signer.SignRound1(this.message);
                this.signersMessages = [];
                if (p2p instanceof Error)
                    throw p2p;
                for (let [dst, data] of p2p) {
                    if (dst != this.idx)
                        this.sendMsg({ round: 1, data, from: this.idx }, dst);
                }
                break;
            }
            case 2: {
                console.log('this.signersMessages', this.signersMessages);
                const bcast = this.signer.SignRound2(this.signersMessages);
                broadcast(bcast);
                break;
            }
            case 3: {
                const signature = this.signer.SignOutput(this.signersMessages);
                this.signersMessages = [];
                if (signature instanceof Error)
                    throw signature;
                this.sendMsg({ round: this.round, from: this.idx, signature }, 0);
                this.resolve(signature);
                this.signer.Release();
            }
        }
    }
}
exports.Ted25519Signer = Ted25519Signer;
class GG20Participant {
    constructor(participant) {
        this.participant = participant;
        this.idx = JSON.parse(participant).Id;
    }
    sign(digest, cosigners, signingRoom, gg20Instance) {
        return new Promise((resolve, reject) => {
            try {
                const signer = new GG20Signer(this.participant, this.idx, digest, cosigners, signingRoom.sendMsg, gg20Instance);
                signingRoom.onmessage = (msg) => {
                    signer.handleMessage(msg);
                };
                signer.sign().then(resolve).catch(reject);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
exports.GG20Participant = GG20Participant;
class Ted25519Participant {
    constructor(config, participant) {
        this.config = config;
        this.participant = participant;
        this.idx = JSON.parse(participant).identifier;
    }
    sign(digest, cosigners, signingRoom, ted25519Instance) {
        return new Promise((resolve, reject) => {
            try {
                const signer = new Ted25519Signer(this.config, this.participant, this.idx, digest, cosigners, signingRoom.sendMsg, ted25519Instance);
                signingRoom.onmessage = (msg) => signer.handleMessage(msg);
                signer.sign().then(resolve).catch(reject);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
exports.Ted25519Participant = Ted25519Participant;
