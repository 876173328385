import React from "react";
import {Avatar, Box, Button, Container, TextField, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import useSocket from "../../hooks/useSocket";
import QRCode from "react-qr-code";
import { v4 } from "uuid";


const SignPage = (props) => {
    const [digest, setDigest] = React.useState(props.digest);
    const [roomId, setRoomId] = React.useState(props.sessionId);
    const { init, roomParticipants, signature, sign } = useSocket(props.socketServerUrl, props.mode);

    React.useEffect(() => {
        init(roomId, props.currentParticipant);
    }, [props.socketServerUrl]);

    React.useEffect(() => {
        if (!signature) {
            return
        }

        setRoomId('');
    }, [signature]);

    React.useEffect(() => {
        init(roomId, props.currentParticipant);
    }, [props.currentParticipant]);

    const handleDigestChange = React.useCallback((event) => {
        setDigest(event.target.value);
    }, []);

    const handleRoomIdChange = React.useCallback((event) => {
        setRoomId(event.target.value);
    }, []);

    const handleInitSignRoom = React.useCallback(() => {
        if (!digest) {
            alert('Enter digest to sign first');
            return;
        }
        const sessionId = v4();
        setRoomId(sessionId);
        init(sessionId, props.currentParticipant);
    }, [digest, props.currentParticipant]);

    const connectSignRoom = React.useCallback(() => {
        if (!roomId) {
            alert('Enter room id first');
            return;
        }
        if (!digest) {
            alert('Enter digest to sign first');
            return;
        }
        init(roomId, props.currentParticipant);
    }, [digest, roomId, props.currentParticipant]);

    const handleSignClick = React.useCallback(() => {
        console.log('handleSignClick', handleSignClick)
        sign(digest);
    }, [digest, sign]);

    const qrCodeValue = React.useMemo(() => {
        const query = {
            sessionId: roomId,
            digest: digest,
            mode: props.mode,
            socketServerUrl: props.socketServerUrl
        };
        const queryString = Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');

        return `${window.location.href}?${queryString}`;
    }, [digest, roomId, roomParticipants, props.mode]);

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <EditIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Generate signature
            </Typography>

            <TextField
                margin="normal"
                required
                fullWidth
                id="digest"
                label="Digest to sign"
                name="digest"
                type="text"
                autoComplete="off"
                value={digest}
                onChange={handleDigestChange}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="roomId"
                label="Room id"
                name="roomId"
                type="text"
                autoComplete="off"
                value={roomId}
                onChange={handleRoomIdChange}
            />

            <Box sx={styles.item}>
                <Button sx={styles.button} onClick={handleInitSignRoom} variant="outlined">
                    Init sign room
                </Button>

                <Button sx={styles.button} onClick={connectSignRoom} variant="outlined">
                    Connect
                </Button>
            </Box>

            {roomParticipants.length > 0 &&
                <>
                    <Box sx={styles.qrCodeBox}>
                        <QRCode value={qrCodeValue}/>
                    </Box>
                    <Typography>
                        Participants: {JSON.stringify(roomParticipants)}
                    </Typography>
                    <a href={qrCodeValue}>Link</a>
                </>
            }

            {signature &&
                <Typography sx={styles.text}>
                    Signature: {JSON.stringify(signature.signature)}
                </Typography>
            }


            <Button
                type="button"
                fullWidth
                variant="contained"
                sx={styles.button}
                disabled={roomParticipants.length < 2}
                onClick={handleSignClick}
            >
                Generate
            </Button>

        </Container>
    );
}

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        mt: 1,
        bgcolor: "secondary.main"
    },
    loader: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    item: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        m: 2,
        height: '100%'
    },
    text: {
        wordBreak: "break-all",
        my: 1
    },
    qrCodeBox: {
        m: 3
    }
};

export default SignPage;
