import {ethers} from "ethers";
import {isHexString, isValidAddress} from "ethereumjs-util";
import {ethersProvider, solanaProvider} from "../common/api/provider";
import * as web3 from '@solana/web3.js';
import {ed25519ToSolanaPublicKey} from "../utils";

export const generateRawGG20Transaction = async (address, data) => {
    if (!isValidAddress(address)) {
        throw new Error('Address is not valid');
    }
    if (!data || !isValidAddress(data.recipient)) {
        throw new Error('Recipient is not valid');
    }

    const estimatedGasPrise = await ethersProvider.estimateGas({
        to: data.recipient,
        ...(isHexString(data.txData) && { data: data.txData }),
        value: ethers.utils.parseEther(data.txValue || 1).toHexString()
    });
    const obj = {
        to: data.recipient,
        ...(isHexString(data.txData) && { data: data.txData }), // Optional field
        value: ethers.utils.parseEther(data.txValue || 1).toHexString(),
        chainId: (await ethersProvider.getNetwork()).chainId,
        nonce: await ethersProvider.getTransactionCount(address, 'pending'),
        gasLimit: ethers.utils.hexlify(1000000),
        gasPrice: estimatedGasPrise
    };
    console.log('object for creating transaction', obj);

    const tx = ethers.utils.serializeTransaction(obj);
    const digest = ethers.utils.keccak256(tx);
    console.log('transaction hash', tx);
    console.log('digest', digest);

    return { tx, digest };
}

export const generateRawEd25519Transaction = async (from, data) => {
    const pubkey = ed25519ToSolanaPublicKey(from);
    const recentBlockhash = await solanaProvider.getRecentBlockhash();
    let manualTransaction = new web3.Transaction({
        recentBlockhash: recentBlockhash.blockhash,
        feePayer: pubkey
    });
    manualTransaction.add(web3.SystemProgram.transfer({
        fromPubkey: pubkey,
        toPubkey: new web3.PublicKey(data.recipient),
        lamports: data.txValue,
    }));
    const transactionBuffer = manualTransaction.serializeMessage();
    console.log('Sol transaction info', {
        payer: pubkey.toString(),
        transaction: manualTransaction,
        tx: JSON.stringify(manualTransaction),
        digest: Buffer.from(transactionBuffer).toString('hex')
    })

    return {
        payer: pubkey,
        transaction: manualTransaction,
        tx: JSON.stringify(manualTransaction),
        digest: Buffer.from(transactionBuffer).toString('hex')
    }
}
