import React from "react";
import {Avatar, Box, Button, Container, TextField, Typography} from "@mui/material";
import {ethers} from "ethers";
import {signTransaction} from "../../functions/sign-transaction";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import modes from "../../constants/modes";


const SignTransactionPage = (props) => {
    const [signedTx, setSignedTx] = React.useState(null);

    const handleSignTransaction = React.useCallback(async (event) => {
        /* Form data */
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const parsedData = {
            rawTx: data.get("rawTx"),
            R: data.get("R"),
            S: data.get("S")
        };

        let signedTransaction = null;

        if (props.mode === modes.ethereum) {
            const from = props.participant.gg20.address;
            signedTransaction = await signTransaction(parsedData.rawTx, {R: parsedData.R, S: parsedData.S}, from);
        } else if (props.mode === modes.solana) {
            if (props.transaction) {
                props.transaction.addSignature(props.payer, Buffer.from(parsedData.rawTx, 'hex'));
                let isVerifiedSignature = props.transaction.verifySignatures();
                console.log(`The signatures were verifed: ${isVerifiedSignature}`);

                signedTransaction = Buffer.from(props.transaction.serialize()).toString('hex');
            } else {
                console.warn('Create raw transaction first');
            }
        }

        setSignedTx(signedTransaction);
    }, [props.transaction, props.payer, props.mode]);

    const parsedSignature = React.useMemo(() => {
        let parsedSig;

        if (signedTx && props.mode === modes.ethereum) {
            parsedSig = ethers.utils.parseTransaction(signedTx);
        } else {
            parsedSig = signedTx
        }

        return parsedSig;
    }, [signedTx, props.mode])

    return (
        <Container maxWidth="xs" sx={styles.container}>
            <Avatar sx={styles.avatar}>
                <BorderColorIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign transaction
            </Typography>

            <Box
                component="form"
                onSubmit={handleSignTransaction}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="raw-tx"
                    label="Unassigned transaction"
                    name="rawTx"
                    type="text"
                    autoComplete="off"
                />

                {props.mode === modes.ethereum &&
                    <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="R"
                            label="R"
                            name="R"
                            type="text"
                            autoComplete="off"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="S"
                            label="S"
                            name="S"
                            type="text"
                            autoComplete="off"
                        />
                    </>
                }

                {signedTx &&
                    <>
                        <Typography>
                            Signed transaction:
                        </Typography>
                        <Typography sx={styles.text}>
                            {signedTx}
                        </Typography>
                    </>
                }

                {parsedSignature &&
                    <>
                        <Typography>
                            Parsed transaction:
                        </Typography>
                        <Typography sx={styles.text}>
                            {JSON.stringify(parsedSignature)}
                        </Typography>
                    </>
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={styles.button}
                >
                    Sign
                </Button>
            </Box>
        </Container>
    );
}

const styles = {
    container: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        m: 1,
        bgcolor: "secondary.main"
    },
    button: {
        mt: 3,
        mb: 2
    },
    text: {
        wordBreak: "break-all",
        my: 1
    },
};

export default SignTransactionPage;