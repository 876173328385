import * as tss from "@xdefi-tech/crypto_tss";
import {predefinedSafePrimes as psp} from "../constants/predefinedSafePrimes";
import JSONBig from "json-bigint";

const generateDealer = async (data) => {
    await tss.ThresholdSigning.Init();
    const { threshold, shares, genSafePrimes } = data;
    const config = {
        T: threshold,
        N: shares,
    };

    // if (threshold !== 2 || shares !== 3) {
    //     // TODO add more threshold and shares support
    //     alert("Threshold = 2; Shares = 3 only");
    //     return;
    // }

    const pcount = (config.N + 1) * 2;
    let predefinedSafePrimes;

    if (genSafePrimes) {
        // TODO generate safe primes at worker based on genSafePrimes
        alert('Generating safe primes is not yet supported');
        return;
    } else {
        predefinedSafePrimes = psp.slice(0, pcount);
    }

    const [ecdsaPublicKey, ecdsaParticipants] = globalThis.gg20.InitParticipants(config, predefinedSafePrimes);
    const [ed25519PublicKey, ed25519Participants] = globalThis.ted25519.InitParticipants(config);

    console.log({
        config,
        ecdsaPublicKey,
        ecdsaParticipants,
        ed25519PublicKey,
        ed25519Participants
    })
    console.log(
        JSONBig.stringify({
            config,
            ecdsaPublicKey,
            ecdsaParticipants,
            ed25519PublicKey,
            ed25519Participants
        })
    );

    return {
        config,
        ecdsaPublicKey,
        ecdsaParticipants,
        ed25519PublicKey,
        ed25519Participants
    };
}

export default generateDealer;
