"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ted25519Dealer = exports.GG20Dealer = void 0;
const init_lib_1 = require("./init_lib");
var GG20Dealer;
(function (GG20Dealer) {
    function InitParticipants(config, primes) {
        const r = init_lib_1.gg20.InitParticipants(config, primes);
        if (r instanceof Error)
            throw r;
        return r;
    }
    GG20Dealer.InitParticipants = InitParticipants;
})(GG20Dealer = exports.GG20Dealer || (exports.GG20Dealer = {}));
var Ted25519Dealer;
(function (Ted25519Dealer) {
    function InitParticipants(config) {
        const r = init_lib_1.ted25519.InitParticipants(config);
        if (r instanceof Error)
            throw r;
        return r;
    }
    Ted25519Dealer.InitParticipants = InitParticipants;
})(Ted25519Dealer = exports.Ted25519Dealer || (exports.Ted25519Dealer = {}));
