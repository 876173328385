import React from "react";
import {Box, Button, TextField} from "@mui/material";
import { io } from "socket.io-client";
import QrScannerComponent from "../scanner/qr-scanner.component";


const ImportParticipantComponent = (props) => {
    const [encodedData, setEncodedData] = React.useState('');
    const [offer, setOffer] = React.useState(null);
    const [signalingRoom, setSignalingRoom] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openQrScanner, setOpenQrScanner] = React.useState(false);

    React.useEffect(() => {
        let receiverPeer = null;
        let signalingClient = null;

        try {
            if (!offer || !signalingRoom) {
                return;
            }
            setLoading(true);
            console.log('offer', offer);
            console.log('signalingRoom', signalingRoom);



            receiverPeer = new window.SimplePeer({
                initiator: false,
                trickle: false
            });

            const url = new URL(props.socketServerUrl)
            signalingClient = io(url.origin, {
                query: {
                    roomId: signalingRoom,
                },
                transports: ['websocket'],
                path: `${url.pathname}${url.pathname.endsWith('/') ? '' : '/'}signaling`,
            })

            console.log(signalingClient);

            receiverPeer.on("signal", (data) => {
                const answer = btoa(JSON.stringify(data));
                console.log('ANSWER', answer)
                signalingClient.emit('send-answer', {answer});
            })

            receiverPeer.on("data", (uint8array) => {
                const decodedArray = new TextDecoder().decode(uint8array);
                props.onImport(decodedArray);
                setOffer(null);
                setSignalingRoom(null);
                setEncodedData('');
                setLoading(false);
            })

            receiverPeer.signal(offer);
        } catch (err) {
            console.error(err);
            setLoading(false);
            receiverPeer?.destroy();
            receiverPeer = null;
            signalingClient?.disconnect();
            signalingClient = null;
        }

        return () => {
            receiverPeer?.destroy();
            receiverPeer = null;
            signalingClient?.disconnect();
            signalingClient = null;
        };
    }, [offer])

    const handleChangeEncodedData = React.useCallback((event) => {
        setEncodedData(event.target.value);
    }, []);

    const handleDecodeData = React.useCallback(() => {
        const rawData = atob(encodedData);
        const {offer, signalingRoom} = JSON.parse(rawData);

        setSignalingRoom(signalingRoom);
        setOffer(offer);
    }, [encodedData]);

    const handleToggleQrScanner = React.useCallback(() => {
        setOpenQrScanner((prevState) => !prevState);
    }, []);

    const handleScanQrCode = React.useCallback((data) => {
        setEncodedData(data);
        setOpenQrScanner(false);
    }, []);

    return (
        <Box>
            {
                loading && <Box>LOADING...</Box>
            }
            <Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="encoded"
                    label="Import data from export"
                    name="encoded"
                    type="text"
                    autoComplete="off"
                    value={encodedData}
                    onChange={handleChangeEncodedData}
                />

                <Button
                    type="button"
                    fullWidth
                    onClick={handleDecodeData}
                    variant="outlined"
                    disabled={!encodedData}
                >
                    Import
                </Button>

                <Button
                    type="button"
                    fullWidth
                    onClick={handleToggleQrScanner}
                    variant="outlined"
                >
                    {openQrScanner ? 'Close' : 'Open'} QR Reader
                </Button>

                {openQrScanner &&
                    <QrScannerComponent
                        onScan={handleScanQrCode}
                    />
                }
            </Box>
        </Box>
    );
}

export default ImportParticipantComponent;
