import React from "react";
import { Box, Typography } from "@mui/material";
import { io } from "socket.io-client";
import JSONBig from "json-bigint";
import QRCode from "react-qr-code";
import { v4 } from 'uuid';

const ExportParticipantComponent = (props) => {
    const [encodedData, setEncodedData] = React.useState(null);
    const [gg20Idx, setGg20Idx] = React.useState(null);
    const [ted25519Idx, setTed25519Idx] = React.useState(null);

    React.useEffect(() => {
        const participant = JSONBig.parse(props.participantStr);
        setGg20Idx(JSON.parse(participant.gg20).Id);
        setTed25519Idx(JSON.parse(participant.ted25519.ed25519Participant).identifier);

        let peer = null;
        let signalingClient = null;

        peer = new window.SimplePeer({
            initiator: true,
            trickle: false
        })

        peer.on("signal", (data) => {
            if (data.type !== "offer") {
                return;
            }

            const roomId = v4();

            const offerData = {
                offer: data,
                signalingRoom: roomId
            }

            const encodedData = btoa(JSON.stringify(offerData));
            setEncodedData(encodedData);

            const url = new URL(props.socketServerUrl)
            signalingClient = io(url.origin, {
                query: {
                    roomId
                },
                transports: ['websocket'],
                path: `${url.pathname}${url.pathname.endsWith('/') ? '' : '/'}signaling`,
            })

            signalingClient.on("answer", ({ answer }) => {
                console.log('ANSWERED', answer);
                peer.signal(JSON.parse(atob(answer)));
            })
        })

        peer.on("connect", () => {
            console.log('Connected to peer, sending participant');
            peer.send(props.participantStr);
            props.onReset();
        })

        return () => {
            peer?.destroy();
            peer = null;
            signalingClient?.disconnect();
            signalingClient = null;
        }
    }, [props.participantStr]);

    return (
        <Box>
            <Typography>
                Export participant
            </Typography>
            <Typography>
                GG20 id: {gg20Idx}
            </Typography>
            <Typography>
                ted25519 id: {ted25519Idx}
            </Typography>

            <br/>

            {encodedData &&
                <>
                    <Typography>
                        Encoded data:
                    </Typography>
                    <Typography sx={styles.text}>
                        {encodedData}
                    </Typography>

                    <Box sx={styles.qrCodeBox}>
                        <QRCode value={encodedData}/>
                    </Box>
                </>
            }
        </Box>
    );
}

const styles = {
    text: {
        wordBreak: "break-all",
        my: 1
    },
    qrCodeBox: {
        m: 3
    }
}

export default ExportParticipantComponent;
