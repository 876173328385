import "./App.css";
import React from "react";
import * as tss from "@xdefi-tech/crypto_tss";
import * as wasmInit from "@xdefi-tech/crypto_tss/dist/wasm_exec";
import {parseParticipant} from "./functions/parse-participant";
import {useLocation} from "react-router-dom";
import * as JSONBig from "json-bigint";
import modes from "./constants/modes";
import {TextField, MenuItem} from "@mui/material"
import DealerPage from "./pages/dealer/dealer.page";
import RawTransactionPage from "./pages/rawTransaction/raw-transaction.page";
import ParticipantPage from "./pages/participant/participant.page";
import BalancePage from "./pages/balance/balance.page";
import SignPage from "./pages/sign/sign.page";
import SignTransactionPage from "./pages/signTransaction/sign-transaction.page";
import BroadcastPage from "./pages/broadcast/broadcast.page";
import SettingsPage from "./pages/settings/settings.page";

console.log({ tss, wasmInit });
window.gg20 = tss.gg20;
window.ted25519 = tss.ted25519;


const App = () => {
    const [transaction, setTransaction] = React.useState(null);
    const [payer, setPayer] = React.useState(null);
    const [mode, setMode] = React.useState('');
    const [dealer, setDealer] = React.useState(null);
    const [currentParticipant, setCurrentParticipant] = React.useState(null);
    const [socketServerUrl, setSocketServerUrl] = React.useState(
        localStorage.getItem('socketServerUrl') ||
        process.env.REACT_APP_API_URL ||
        `${window.location.protocol}//${window.location.hostname}/api`
    );
    const [digest, setDigest] = React.useState('');
    const [sessionId, setSessionId] = React.useState('');
    const location = useLocation();

    React.useEffect(() => {
        // Init tss functions
        tss.ThresholdSigning.Init().then((obj) => {
            console.log('Init ThresholdSigning', obj);
        });

        const storedMode = localStorage.getItem('mode');
        const storedDealer = localStorage.getItem('dealer');
        const storedParticipant = localStorage.getItem('participant');

        // Get data from localstorage
        try {
            if (storedMode) {
                setMode(storedMode);
            }
            if (storedDealer) {
                setDealer(JSONBig.parse(storedDealer));
            }
            if (storedParticipant) {
                const parsedParticipant = parseParticipant(storedParticipant);
                setCurrentParticipant(parsedParticipant);
            }
        } catch (err) {
            console.error(err);
            setMode(null);
            setDealer(null);
            setCurrentParticipant(null);
            localStorage.removeItem('mode');
            localStorage.removeItem('dealer');
            localStorage.removeItem('participant');
        }

        // Parse query
        const queryParams = new URLSearchParams(location.search);
        try {
            const digest = queryParams.get('digest');
            const sessionId = queryParams.get('sessionId');
            const mode = queryParams.get('mode');
            const querySocketServerUrl = queryParams.get('socketServerUrl');

            console.log('socketServerUrl', querySocketServerUrl);

            if (digest && sessionId && mode && querySocketServerUrl) {
                setDigest(digest);
                setSessionId(sessionId);
                setMode(mode);
                setSocketServerUrl(querySocketServerUrl);
                if (!storedParticipant) {
                    alert('Import participant');
                }
            }
        } catch (err) {
            console.error(err);
            queryParams.delete('socketServerUrl');
            queryParams.delete('digest');
            queryParams.delete('sessionId');
            queryParams.delete('mode');
            window.history.replace({
                search: queryParams.toString()
            });
        }

    }, []);

  const handleSetCurrentParticipant = React.useCallback((participantString) => {
    const parsedParticipant = parseParticipant(participantString);
    setCurrentParticipant(parsedParticipant);
    localStorage.setItem('participant', participantString)
  }, []);

  const handleSetSocketServer = React.useCallback((url) => {
      setSocketServerUrl(url);
      localStorage.setItem('socketServerUrl', url);
  }, []);

  const handleSetDealer = React.useCallback((dealer) => {
      setDealer(dealer);
      localStorage.setItem('dealer', JSONBig.stringify(dealer));
  }, []);

    const handleModChange = React.useCallback( (event) => {
        const mode = event.target.value;
        setMode(mode);
        localStorage.setItem('mode', mode);
    }, []);

    const handleSetTransaction = React.useCallback((tx, payer) => {
        setTransaction(tx);
        setPayer(payer);
    }, []);

  return (
      <div className="App">
        <SettingsPage
            socketServerUrl={socketServerUrl}
            setSocketServerUrl={handleSetSocketServer}
        />
        <DealerPage
            dealer={dealer}
            setDealer={handleSetDealer}
            setCurrentParticipant={handleSetCurrentParticipant}
            socketServerUrl={socketServerUrl}
        />
        <ParticipantPage
            currentParticipant={currentParticipant}
            setCurrentParticipant={handleSetCurrentParticipant}
            socketServerUrl={socketServerUrl}
        />

          <TextField
              id="mode-input"
              select
              label="Mode"
              value={mode}
              onChange={handleModChange}
              helperText="Select mode"
              sx={{mt: 6}}
          >
              {Object.values(modes).map((m) => (
                  <MenuItem key={m} value={m}>
                      {m}
                  </MenuItem>
              ))}
          </TextField>

        {currentParticipant && mode &&
            <>
              <RawTransactionPage
                  mode={mode}
                  participant={currentParticipant}
                  setTransaction={handleSetTransaction}
              />
              <SignPage
                  socketServerUrl={socketServerUrl}
                  currentParticipant={currentParticipant}
                  digest={digest}
                  sessionId={sessionId}
                  mode={mode}
              />
              <SignTransactionPage
                  participant={currentParticipant}
                  mode={mode}
                  transaction={transaction}
                  payer={payer}
              />
              <BroadcastPage
                  mode={mode}
                  transaction={transaction}
              />
              <BalancePage
                mode={mode}
              />
            </>
        }
      </div>
  );
}

export default App;
