import React from "react";
import { QrReader } from "react-qr-reader";
import {Box, Button} from "@mui/material";

const FACING_MODE = {
    environment: 'environment',
    user: 'user'
}

const QrScannerComponent = (props) => {
    const [facingMode, setFacingMode] = React.useState(FACING_MODE.environment);
    const handleScanResult = React.useCallback((result, error) => {
        if (error || !result) {
            return;
        }

        props.onScan(result?.text)
    }, []);

    const handleToggleMode = React.useCallback(() => {
        setFacingMode((prevMode) => {
            if (prevMode === FACING_MODE.user) {
                return FACING_MODE.environment;
            }

            return FACING_MODE.user;
        })
    }, []);

    return (
        <Box>
            <QrReader
                constraints={{
                    facingMode: { ideal: facingMode }
                }}
                onResult={handleScanResult}
                style={{ width: "200px", heigth: "100px" }}
            />

            <Button
                type="button"
                fullWidth
                onClick={handleToggleMode}
            >
                Toggle camera
            </Button>
        </Box>

    );
}

export default QrScannerComponent;
