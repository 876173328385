import React from "react";
import {Box, Button, Checkbox, FormControlLabel, TextField} from "@mui/material";


const GenerateDealerForm = (props) => {
    const handleSubmit = React.useCallback((event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.debug({ data });
        const parsedData = {
            threshold: Number(data.get("threshold")),
            shares: Number(data.get("shares")),
            genSafePrimes: Boolean(data.get("genSafePrimes")),
        };
        console.debug({ parsedData });
        props.onSubmit(parsedData);
    }, []);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={styles.form}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="threshold"
                label="Threshold"
                name="threshold"
                type="number"
                autoComplete="off"
                autoFocus
                defaultValue={2}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="shares"
                label="Shares"
                type="number"
                id="shares"
                autoComplete="off"
                defaultValue={3}
            />
            <FormControlLabel
                id="genSafePrimes"
                name="genSafePrimes"
                control={<Checkbox value="false" color="primary" />}
                label="Generate safe primes (this is long)"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={styles.button}
            >
                Generate
            </Button>
        </Box>
    );
}

const styles = {
    button: {
        mt: 3,
        mb: 2
    },
    form: {
        mt: 1
    }
}

export default GenerateDealerForm;
